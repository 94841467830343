import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Link } from "react-router-dom";
import "./DrawerReact.css";

import MenuBookIcon from '@mui/icons-material/MenuBook';
import CallIcon from "@mui/icons-material/Call";
import RoomServiceIcon from '@mui/icons-material/RoomService';
import HomeIcon from "@mui/icons-material/Home";
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import PeopleIcon from "@mui/icons-material/People";


const DrawerReact = (props) => {



  return (
    <div className="dr">
      <Drawer open={props.open} onClose={props.onclose} direction="left">
        <h4 className="Drawerh4a">Smile Cafe Point</h4>

        <div className="mynav-menu" onClick={props.onclose}>
         

           
        <li>

            <Link to="/">
              <HomeIcon />
              Home
            </Link>
          </li>
          <li>
            <Link to="/menu">
              <MenuBookIcon/>
              Menu
            </Link>
          </li>
          <li>
            <Link to="/service">
              <RoomServiceIcon/>
              Service
            </Link>
          </li>
          <li>
            <Link to="/shop">
              <RoomServiceIcon/>
              Shop
            </Link>
          </li>
          <li>
            <Link to="/about">
              <PeopleIcon />
              About Us
            </Link>
          </li>
          <li>
            <Link to="/booktable">
              <BookOnlineIcon />
              Book Table
            </Link>
          </li>
          <li>
            <Link to="/contact">
              <CallIcon />
              Contact
            </Link>
          </li>
       
        </div>
      </Drawer>
    </div>
  );
};

export default DrawerReact;
