import React from 'react'
import './Contact.css'
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import AccessAlarmsRoundedIcon from "@mui/icons-material/AccessAlarmsRounded";
import AttachEmailRoundedIcon from '@mui/icons-material/AttachEmailRounded';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';

const Cdetails = () => {
  return (
    <div className='c2a1'>
    <div className="c2">
    <div className="c2-cards">
      <div className="c-iconbox">
        <FmdGoodRoundedIcon fontSize="large" className="c-icon" />
      </div>
      <h4>Location</h4>
      <h6>Anand</h6>
    </div>
    <div className="c2-cards">
      <div className="c-iconbox">
        <AttachEmailRoundedIcon fontSize="large" className="c-icon" />
      </div>
      <h4>Email</h4>
      <h6>help@smilecafepoint.com</h6>
    </div>
    <div className="c2-cards">
      <div className="c-iconbox">
        <PhoneInTalkRoundedIcon fontSize="large" className="c-icon" />
      </div>
      <h4> Contact Us</h4>
      <h6>+91 6623450112</h6>
    </div>
    <div className="c2-cards">
      <div className="c-iconbox">
        <AccessAlarmsRoundedIcon fontSize="large" className="c-icon" />
      </div>
      <h4>Working Hours</h4>
      <h6>Mon-Sat(10.00AM-5.30PM)</h6>
    </div>
  </div>
  </div>

  )
}

export default Cdetails