import React, { useContext } from 'react'
import {Card, CardMedia, CardActions } from "@mui/material";
import "./myapi.css";
import MyContext from '../../Context/MyContext';


export const Cartitems = () => {
  const{data,dataload,handleCart,loading,productid,isProductInCart,Navigate} = useContext(MyContext)

  return (
    <div>

    <div className="cardapi1a">

    <h6 className="cardapih6a">--DISCOVER--</h6>
    <h6 className="cardapih6b">Our Coffee,Food,Drinks & Desserts Services</h6>


    <div className="grid ">
    {
        data.map((productItem) => {
        return (

          <>
          {dataload ?
            <p>loading...</p>:
         
          <Card
            className="cards"
            raised={true}
            sx={{ maxWidth: 310, maxHeight: 400 }}
          >

            <CardMedia 
              component="img"
              image={productItem.img}
              alt="GFG Logo"
              className="Cardimg1a"
            />
            <h6 className="p1a">{productItem.title} </h6>
            <p className="p2a">Price.₹.{productItem.price}/-</p>


            <CardActions className="btna1">
            

              {isProductInCart(productItem.id) ?
                <button className="Cartbtn2B" onClick={() =>Navigate('/cart')}> Go To Cart</button> :
                <button className='Cartbtn2A' onClick={() => handleCart(productItem.id)}>
              {productid===productItem.id && loading? 'adding wait...':'ADD TO CART'}  
              </button>
              }
              
            </CardActions>
          </Card>
          }
        

          </>
        );
      })}
    </div>
    </div>

    </div>
    )
}
