import React from "react";
import "./hpage.css";

const Hpage = () => {
  return (
    <div className="homeimg1">
      <p className="pHI1">HAPPY HOURS AT</p>
      <p className="pHI2">SMILE CAFE</p>
      <p className="pHI3">
        " WELCOME HOME TO CAFE POINT, WHERE COFFEE MEETS MOMENTS OF PURE JOY "
      </p>
    </div>
  );
};

export default Hpage;
