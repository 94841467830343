import React, { useContext } from 'react'
import './Alert.css'
import MyContext from '../../Context/MyContext'
import { TiTickOutline } from "react-icons/ti";
import { IoAlertCircleOutline } from "react-icons/io5";


const Alert = () => {

    const { sneck, message } = useContext(MyContext);

    
  return (
    <>

 { sneck &&  <div class="alert-form" style={{backgroundColor:message.match('Thanks')?'green':'red'}}>
    <span>{message.match('Thanks')?<TiTickOutline/>:<IoAlertCircleOutline />}</span>
   {message}
  </div>}
  </>
  )
}

export default Alert