import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button } from '@mui/material';
import './Checkout.scss';
import MyContext from '../Context/MyContext';
import Ship from './Ship/Ship';
import { useLocation } from 'react-router-dom';
import { ArrowLeftSharp } from '@mui/icons-material';



const Checkout = () => {

  

    const location = useLocation()

    const Token = sessionStorage.getItem('token')
    const {cartorder,Navigate,setSneck,setOpenship,setMessage,setLoading,loading,TotalValue,shippinginfo,openship} = useContext(MyContext)
    console.log(cartorder)

    const formik = useFormik({
        initialValues: {
            name: shippinginfo?.name || '',
            mobile: shippinginfo?.mobile || '',
            email: shippinginfo?.email || '',
            address: shippinginfo?.address || '',
            state: shippinginfo?.state || '',
            pincode: shippinginfo?.pincode || '',
            landmark: shippinginfo?.landmark || '',
            city: shippinginfo?.city || '',
            alternate: shippinginfo?.alternate || '',
          },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            mobile: Yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Mobile is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            address: Yup.string().required('Address is required'),
            state: Yup.string().required('State is required'),
            pincode: Yup.string()
                .matches(/^[0-9]+$/, 'Pincode must only contain numbers')
                .required('Pincode is required'),
            city: Yup.string().required('City is required'),
        }),
        onSubmit: async(values,{ resetForm }) => {
       setLoading(true)
             const response = await fetch('https://emongo.vercel.app/checkout', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${Token}`
                },
            body: JSON.stringify(values),
            
            });

            const data = await response.json()
            if(data.success){
                setMessage(data.message)
                setSneck(true)
                setTimeout(() => {
                    window.location.reload() 
                }, 2000);
             
       
                resetForm()
            }
            else{
               setMessage(data.error)
               setSneck(true)
              
          }
        setLoading(false)
        
             
             
            
            
            
            },
            });

    return (
       
       <>
       {Token ?
      <div className='Checkout_main'>
      
       <div className="all-in-one">
      {openship && <span onClick={() =>setOpenship(false)}><ArrowLeftSharp /> Go Back</span>}
    <h1>Shipping Address</h1>

    {openship? 


        <form onSubmit={formik.handleSubmit}>
       
            <div className='row1'>
                <TextField
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                    id="mobile"
                    name="mobile"
                    placeholder="Mobile Number"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                    helperText={formik.touched.mobile && formik.errors.mobile}
                />
            </div>
            <div className='row1'>
                <TextField
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                    id="state"
                    name="state"
                    placeholder="State"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                />
            </div>
            <div className="add">
            <TextField
                id="address"
                name="address"
                multiline
                rows={3}
                placeholder="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
            />
            </div>
            <div className='row1'>
                <TextField
                    id="landmark"
                    name="landmark"
                    placeholder="landmark (optional)"
                    value={formik.values.landmark}
                    onChange={formik.handleChange}
                />
                <TextField
                    id="pincode"
                    name="pincode"
                    placeholder="Pincode"
                    value={formik.values.pincode}
                    onChange={formik.handleChange}
                    error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                    helperText={formik.touched.pincode && formik.errors.pincode}
                />
            </div>
            <div className='row1'>
                <TextField
                    id="city"
                    name="city"
                    placeholder="City"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                />
                <TextField
                    id="alternate"
                    name="alternate"
                    placeholder="Alternate Phone (Optional)"
                    value={formik.values.alternate}
                    onChange={formik.handleChange}
                />
            </div>
            <div className="sub">
            <Button className='btno' style={{backgroundColor:'black'}}  type="submit" variant="contained">{loading?'Submitting':shippinginfo.address?'update':'Submit'}</Button>
            </div>
        </form>:
     <Ship/>

    }
        </div>

     {!location.pathname.includes('/shipping-info') &&  <div className="checkout">

<h3>summary</h3>

<div className="inner">
<div className="price">
  <span>Product Price:</span>
  <span>₹{TotalValue}</span>
</div>

<div className="price">
  <span>Delivery Charge:</span>
  <span>₹0</span>
</div>

<div className="price">
  <span>Handling Charge:</span>
  <span>free</span>
</div>

<div className="price">
  <span>Total Price:</span>
  <span>₹{TotalValue}</span>
</div>


</div>


</div>}

        
        </div>:

        Navigate('/login')

}
        </>

    
      
     
    );
};

export default Checkout;