import React from "react";
import "./Service.css";

import Servicesec2 from "./Servicesec2";

const Service = () => {
  return (
    <div>
      <div className="Servicebg-1">
        <p className="Sp1">OUR SERVICES</p>
        <p className="Sp2">
          " WARM AND WELCOMING ATMOSPHERE: EXPERIENCE A HAVEN OF HOSPITALITY AND COMFORT "
        </p>
      </div>
      <Servicesec2/>
      
    </div>
  );
};

export default Service;
