import React from "react";
import "./Footer.css";
// import { Link } from "react-router-dom";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <div className="footermain">
      <div className="Fcontain">
        <div className="Fa1">
          <h4 className="Fa1h4">Smile Cafe Point </h4>
          <p className="Fpa1">We provide the best service for you</p>
          <button className="Fbtna1"><a href="/booktable">Book Table</a></button>
          <p className="Fpa1">Best food & Best offers</p>
        </div>
        <div className="Fa1">
          <h4 className="Fa1h4">About Us</h4>
          <p className="Fpa1">About Cafe</p>
          <p className="Fpa1">Our Chef Team</p>
          <p className="Fpa1">Booking Info</p>
        </div>
        <div className="Fa1">
          <h4 className="Fa1h4">Quick Links</h4>
          <p className="Fpa1">
            <a href="/">Home</a>
          </p>
          <p className="Fpa1">
            <a href="/menu">Menu</a>
          </p>
          <p className="Fpa1">
            <a href="/about">About Us</a>
          </p>
          <p className="Fpa1">
            <a href="/service">Service</a>
          </p>
          <p className="Fpa1">
            <a href="/contact">Contact</a>
          </p>
        </div>
        <div className="Fa1">
          <h4 className="Fa1h4">Contact Us</h4>
          <p className="Fpa1">book@smilecafepoint.com</p>
          <p className="Fpa1">1-444-123-4559 </p>
          <p className="Fpa1">Nizampura,Vadodara</p>
          <div className="Ficonlist">
            <div className="listicona">
              <FacebookRoundedIcon />
            </div>
            <div className="listicona">
              <InstagramIcon />
            </div>
            <div className="listicona">
              <TwitterIcon />
            </div>
            <div className="listicona">
              <LinkedInIcon />
            </div>
          </div>
        </div>
      </div>

      <div className="lastfooter">
        <p className="pF1a">
          Copyright © 2023 Smile Cafe Point, All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
