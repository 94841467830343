import React from "react";
import './menu.css';
import Mlist1 from "./Mlist1";

const Menu = () => {
  return (
    <div>
      <div className="Menubg-1">
        <p className="Mp1">Menu</p>
        <p className="Mp2">
        " TASTE THE DIFFERENCE AS OUR EXCEPTIONAL INGREDIENTS AND SKILLED CHEFS CREATE MAGIC ON YOUR PLATE " 
        </p>
      </div>

      <Mlist1/>
    </div>
  );
};

export default Menu;
