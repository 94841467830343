import React, { useContext } from 'react'
import './Payment.scss'
import MyContext from '../Context/MyContext'

const Payment = () => {

  
const Token = sessionStorage.getItem('token')
    const {Navigate,handleconfirm} = useContext(MyContext)
  return (
<>
    {Token ?

       
        <div class="main-payment">
        <h1>Choose a Payment Method</h1>
       <div class="payment">
            <div class="payment-method">
               
              <img src="https://i.ibb.co/vV5Y3X3/gpay.png" alt="GPay"/> Google Pay
              <div className='overlay'>not available</div>
            </div>
    
            <div class="payment-method">
              
             <img src="https://i.ibb.co/8KwhwXm/download-2.png" alt="Paytm"/> Paytm
             <div className='overlay'>not available</div>
            </div>
    
            <div class="payment-method">
                
               <img src="https://i.ibb.co/kMQwswV/credit.jpg" alt="Credit Card"/> Credit Card
               <div className='overlay'>not available</div>
            </div>
    
            <div class="payment-method">
              
         <img src="https://i.ibb.co/9qHGtvQ/debit.jpg" alt="Debit Card"/> Debit Card
         <div className='overlay'>not available</div>
            </div>
    
            <div class="payment-method">
               
    <img src="https://i.ibb.co/HNxP3gB/cod.png" alt="Cash on Delivery"/> Cash on Delivery

            </div>
    
            <div class="payment-method">
               
        <img src="https://i.ibb.co/6YTsQvs/upi.jpg" alt="UPI ID"/> UPI ID
        <div className='overlay'>not available</div>
            </div>
    
            
            
        </div>

        <p>**other payment is not available due to payment verification,please procced with cash on delivery now</p>
        <button type="submit" onClick={handleconfirm}>Confirm with cod</button>
        </div>
        

      :

   
    
    Navigate('/login')

    }
    </>
   
  )
}

export default Payment