import React from 'react'
import './mlist1.css';
import Data from './menulist1json';


const Mlist1 = () => {
  return (
    <div className="Mlist1a">
      <div className="Mlist1b">

      {Data.map((items)=>{
        return(
    <div className="Ml1a" key={items.id}>
          <div className='Mlistimg'>
            <img
              className="Mlimg1a"
              src={items.img}
              alt=""
            />
          </div>

          <div>
            <h4 className="Mlh4a">{items.title}</h4>
            <p className="Mlpa">
            {items.body}
            </p>
          </div>
        </div>
  )
})}
</div>
</div>
);
};

export default Mlist1;