import React, { useEffect, useContext } from 'react';
import './Order.scss'
import MyContext from '../Context/MyContext';

const Order= () => {
  const Token = sessionStorage.getItem('token');
  const {setOrders,loading,Navigate ,setDataload,dataload,orders,setMessage,setSneck} = useContext(MyContext);
 

  useEffect(() => {
    if(!Token){
     setMessage("please login to view cart")
     setSneck(true)
     setTimeout(() => {
      Navigate('/login')
    }, 3000);
     return;
    }
  
    const fetchBookings = async () => {
      try {
        const response = await fetch('https://emongo.vercel.app/order-info', {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch bookings');
        }
        const data = await response.json();
       
        setOrders(data.orderinfo)
        setDataload(false);
    
      } catch (error) {
        console.error('Error fetching bookings:', error);
        setDataload(false);
     
      }
    };
    fetchBookings();
  }, [Token,setOrders,Navigate,setDataload,setMessage,setSneck]);

  return (
    <div className="cart-container">
    <h2>Order Details</h2>
    {dataload  ? (
      <p>Loading...</p>
    ) : (
      <div className="cart-view">

     
        {orders.length > 0 ? (
          orders
          .sort((a, b) => b.orderdate-a.orderdate)
          .map((item, index) => (
            <div className="cart" key={index}>

            <div className='name-img'>
              <img src={item.img} alt={item.title} />


             
                <div className='pname'>{item.title}</div>
                </div>
               
             <p>Quantity:  ₹{loading ? 0:item.quantity}</p>


              <p>Price:  ₹{loading ? 0:item.quantity*item.price}</p>

              <p>Order Date:  {loading ? 0:new Date(item.orderdate).toLocaleDateString('en-GB')}</p>

            </div>
          ))
        ) : (
          <h3>order Is Empty</h3>
        )}

       
  
      </div>
    )}
  </div>
  
  );
};

export default Order;
