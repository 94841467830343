import React, { useContext } from "react";
import "./booktable.css";
import { useFormik } from "formik";
import * as yup from "yup";
import MyContext from "../../Context/MyContext";

const Booktable = () => {

  const Token = sessionStorage.getItem('token');

  const {setSneck,setMessage,setLoading,loading,Navigate} = useContext(MyContext) 


  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      numofpass: "",
      date: "",
      time: "",
     
    },
    validationSchema:yup.object({
      name: yup
        .string("* Enter your Name")
        .required("* Name is required")
        .matches(/^([^0-9]*)$/, "* donn't enter numeric Value")
        .min(4, "* Name should atleast 4 charcter"),
  
      email: yup
        .string("* Enter your email")
        .matches(/^\S*$/, "* email cannot contain whitespace")
        .email("* Enter a valid email")
        .required("* Email is required"),
  
      mobile: yup
        .string("* Enter your mobile no")
        .matches(/^\S*$/, "* Mobile no cannot contain whitespace")
        .min(10, "* Mobile no should be 10 Number")
        .max(12, "* Mobile no should be 10 Number")
        .required("* Mobile no is required")
        .matches(/^(\+?\d{1,3}[- ]?)?\d{10}$/, "* Mobile number is not valid"),
  
      date: yup.string("* Select pickup date").required("* Select pickup date"),
  
      time: yup.string("* Select pickup time").required("* Select pickup time"),
  
      numofpass: yup
        .string("* Select number of passangers")
        .required("* Select number of passangers"),
    }),
    onSubmit: async (values, { resetForm }) => {
     

  
      if (!Token) {
        Navigate('/login');
        return;
      }
    
      try {
        setLoading(true);
        const response = await fetch('https://emongo.vercel.app/book', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`
          },
          body: JSON.stringify(values)
        });
    
        const data = await response.json()
    
        if (data.success) {
          setMessage(data.message)
          setSneck(true)
          resetForm()
        } else {
          setMessage(data.error)
          setSneck(true)
        }
      } catch (error) {
        setMessage('An error occurred, please try again');
        setSneck(true);
        console.error('Error during booking:', error);
      } finally {
        setLoading(false);
      }
    },
    
  });

  return (
    <div className="form-container">
    {!Token && <div className="over-lay">Login First <span onClick={() => Navigate('/login')}>click here</span></div>}
      <div className="form-righta1">
        <h4 className="bth4">Online Booking</h4>
        <h2 className="bth2">Book Your Table</h2>
      
       
   
        <form className="mainform" onSubmit={formik.handleSubmit}>
          <span>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Your Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            {formik.errors.name && formik.touched.name ? (
              <p className="form-error">{formik.errors.name}</p>
            ) : null}
          </span>
          <span>
            <label>Email-Id:</label>
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Email Id"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.errors.email && formik.touched.email ? (
              <p className="form-error">{formik.errors.email}</p>
            ) : null}
          </span>

          <span>
            <label>Mobile.No:</label>
            <input
              type="text"
              name="mobile"
              id="mobile"
              placeholder="Mobile No"
              value={formik.values.mobile}
              onChange={formik.handleChange}
            />
            {formik.errors.mobile && formik.touched.mobile ? (
              <p className="form-error">{formik.errors.mobile}</p>
            ) : null}
          </span>

          <span>
            <label>Seats/Person:</label>
            <select
              type="select"
              name="numofpass"
              id="numofpass"
              value={formik.values.numofpass}
              onChange={formik.handleChange}
            >
              <option value="">Number of Persons</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
            {formik.errors.numofpass && formik.touched.numofpass ? (
              <p className="form-error">{formik.errors.numofpass}</p>
            ) : null}
          </span>

          <span>
            <label>Date:</label>
            <input
              type="date"
              name="date"
              id="date"
              placeholder="Select Date"
              value={formik.values.date}
              onChange={formik.handleChange}
            />
            {formik.errors.date && formik.touched.date ? (
              <p className="form-error">{formik.errors.date}</p>
            ) : null}
          </span>

          <span>
            <label>Time:</label>
            <input
              type="time"
              name="time"
              id="time"
              placeholder="Select Time"
              value={formik.values.time}
              onChange={formik.handleChange}
            />
            {formik.errors.time && formik.touched.time ? (
              <p className="form-error">{formik.errors.time}</p>
            ) : null}
          </span>

          <button
            className="fsubmit-btn"
            type="submit"
            disabled={loading?true:false}
         
          >
        {  loading ? 'booking...' : 'Book Now'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Booktable;
