import React from 'react'
import Hpage from './Hpage'
import Mlist1 from '../Menu/Mlist1'
import Asec2 from '../About/Asec2'
import ContactForm from '../Contact/ContactForm'
import Homesec4 from './Homesec4'
import HomeSlider from './HomeSlider'

const Home = () => {
  return (
    <div>
      <Hpage/>
      <Homesec4/>
      <HomeSlider/>
      <Mlist1/>
      <Asec2/>
      <ContactForm/>
    </div>
  )
}

export default Home