import React from "react";
import Booktable from "./Booktable";
import './booktable.css'

const Booking = () => {
  return (
    <div>
      <div className="Bookingbg-1">
        <p className="BTp1">Reservation</p>
        <p className="BTp1a">Book a Table</p>
        <p className="BTp2">
          " RESERVE YOUR SPOT AT CAFE POINT TODAY,
          EXPERIENCE A MEMORABLE DINING EXPERIENCE."
        </p>
      </div>
      <Booktable/>
    </div>
  );
};

export default Booking;
