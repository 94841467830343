import React from 'react'
import './Forgot.scss'
import { useContext } from 'react'
import MyContext from '../../../Context/MyContext'
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const Forgot = () => {
    const {forgot,setForgot} = useContext(MyContext)

    const formik = useFormik({
        initialValues: {
        email: '',
   
        
        },
        validationSchema: yup.object({
            email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
         
             
            
            
            }),
        onSubmit: (values) => {
        alert(JSON.stringify(values, null, 2));
        },
        
        });
  return (
    <>
    {forgot &&
        <div  class="modal">
        <div class="modal-content">
            <span class="close" onClick={() => setForgot(false)}>&times;</span>
            <form className='main' onSubmit={formik.handleSubmit}>

            <TextField 
            id="email"
            name="email"
            
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email} 
            label="Email" 
            variant="standard" />
       
            
            <Button variant="contained" type='submit'>Submit</Button>
            
            </form>
            
        </div>
    </div>
    }
    </>
  )
}

export default Forgot