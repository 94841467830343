const Data = [
    {
        "id": 1,
        "img": require("../../assets/Aboutimg/single-food-2.jpg"),
        "title": "DELICIOUS FOOD",
        "body": "Celebrating the Art of Pasta-making by creating dishes that showcase its Versatility and Comforting flavors."
    },
    {
        "id": 2,
        "img": require("../../assets/Aboutimg/single-food-1.jpg"),
        "title": "HOT & SPICY",
        "body": "Hot and Spicy food is an Exhilarating journey of flavors.The heat,ignite your taste buds, and explore the vibrant world of Hot and spicy Cuisine."
    },
    {
        "id": 3,
        "img": require("../../assets/Aboutimg/single-food-6.jpg"),
        "title": "CREAMY WITH CRUNCH",
        "body": "A variety of Dishes that harmoniously blend Creamy textures with Delectable Crunch, offering a Unique and satisfying Dining Experience."
    },
    {
        "id": 4,
        "img": require("../../assets/Tempimg/h-slider3.jpg"),
        "title": "CHOCOLATE BALLS",
        "body": "Chocolate Balls are a feast for the senses, combining rich, velvety chocolate with a variety of delightful fillings and toppings."
    },
    {
        "id": 5,
        "img": require("../../assets/Aboutimg/single-food-7.jpg"),
        "title": "CRUNCHY COOKIES",
        "body": "Crunchy Cookies are carefully crafted with the perfect balance of flavors and textures, making each bite a truly enjoyable Experience."
    },
    {
        "id": 6,
        "img": require("../../assets/Tempimg/h-slider5.jpg"),
        "title": "DELICIOUS DESSERT",
        "body": "Desserts are crafted with passion and creativity, using the finest ingredients to create memorable and indulgent experiences."
    }
]

export default Data