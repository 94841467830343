import React from "react";
import "./About.css";

import FastfoodIcon from '@mui/icons-material/Fastfood';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import CountUp from "react-countup";

const Acountingsec1 = () => {
  return (
    <div className="ACountingsec">
      <div className="Countingsec1">

        <div className="Acountitem1">
          <FastfoodIcon className="about-icons" />
          <h1 className="Countingsech1">
            <CountUp duration={10} end={369} />+
          </h1>
          <h4 className="Countingsech4">Food & Deserts</h4>
        </div>

        <div className="Acountitem1">
          <AccessAlarmIcon className="about-icons" />
          <h1 className="Countingsech1">
            <CountUp duration={10} end={620} />+
          </h1>
          <h4 className="Countingsech4">Working Hours</h4>
        </div>

        <div className="Acountitem1">
          <SentimentSatisfiedAltIcon     className="about-icons" />
          <h1 className="Countingsech1">
            <CountUp duration={15} end={1145} />+
          </h1>
          <h4 className="Countingsech4">Happy Customers</h4>
        </div>

        <div className="Acountitem1">
          <DeliveryDiningIcon className="about-icons" />
          <h1 className="Countingsech1">
            <CountUp duration={10} end={1388} />+
          </h1>
          <h4 className="Countingsech4">Food Delivery</h4>
        </div>
      </div>
    </div>
  );
};

export default Acountingsec1;
