import React, { useContext } from 'react'
import MyContext from '../../Context/MyContext'
import "./Ship.scss"
import { useLocation } from 'react-router-dom'

const Ship = () => {

    const Token = sessionStorage.getItem('token')
const location = useLocation()
    const{shippinginfo,setOpenship,Navigate,dataload} = useContext(MyContext)
  return (
<>

{Token ?
    <>
{!shippinginfo.address?

    <div className='ship-off'>
    <div className='ship-details'>
    <p>Shipping Information is not Aailable,<br/>please add new</p>
    
    </div>
    <button onClick={() =>setOpenship(true)}>add new</button>
    </div>:

   
    <div className='ship-off'>
    {dataload && <p>loading...</p>}
    <div className='ship-details'>
    <p><b>name: </b>{shippinginfo.name?shippinginfo.name:null}</p>
    <p><b>mobile: </b>{shippinginfo.mobile?shippinginfo.mobile:null}</p>
    <p><b>address: </b>{shippinginfo.address?shippinginfo.address:null}</p>
    <p><b>state: </b>{shippinginfo.state?shippinginfo.state:null}</p>
    <p><b>pincode: </b>{shippinginfo.pincode?shippinginfo.pincode:null}</p>
    <p>{shippinginfo.landmark?shippinginfo.landmark:null}</p>
    <p><b>city: </b>{shippinginfo.city?shippinginfo.city:null}</p>
    </div>

    <div className='btn-pro'>
    <button onClick={() =>setOpenship(true)}>update</button>
    {!location.pathname.includes('/shipping-info') &&<button onClick={() => Navigate('/payment')}>Pay now</button>}
    </div>
    </div>
  
  

}

</>:

Navigate('/login')

   
}

    </>
  )
}

export default Ship