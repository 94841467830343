import React from "react";
import "./About.css";
import Asec2 from "./Asec2";
import Acountingsec1 from "./Acountingsec1";
import Aboutsec3 from "./Aboutsec3";

const About = () => {
  return (
    <div>
      <div className="Aboutbg-1">
        <p className="Ap1">ABOUT US</p>
        <p className="Ap2">
          " A WARM AND WELCOMING AMBIANCE, WHERE EVERY GUEST IS FAMILY "
        </p>
      </div>
      <Asec2/>
      <Aboutsec3/>  
      <Acountingsec1/>
    </div>
  );
};

export default About;
