import React, { useEffect, useContext } from 'react';
import MyContext from '../../Context/MyContext';
import "./cartadd.css"
import { IoMdClose } from "react-icons/io";
import { FaLongArrowAltRight } from "react-icons/fa";

const Cartadd = () => {
  const Token = sessionStorage.getItem('token');
  const {setCartitem,handleorder,handleIncrease,handleDecrease,loading,TotalValue,handleDelete,Navigate ,setDataload,dataload,cartitem,setMessage,setSneck} = useContext(MyContext);
 

  useEffect(() => {
    if(!Token){
     setMessage("please login to view cart")
     setSneck(true)
     setTimeout(() => {
      Navigate('/login')
    }, 3000);
     return;
    }
  
    const fetchBookings = async () => {
      try {
        const response = await fetch('https://emongo.vercel.app/cart-info', {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch bookings');
        }
        const data = await response.json();
       
        setCartitem(data.cartinfo)
        setDataload(false);
    
      } catch (error) {
        console.error('Error fetching bookings:', error);
        setDataload(false);
     
      }
    };
    fetchBookings();
  }, [Token,setCartitem,Navigate,setDataload,setMessage,setSneck]);

  return (
    <div className="cart-container">
    <h2>Cart Details</h2>
    {dataload  ? (
      <p>Loading...</p>
    ) : (
      <div className="cart-view">

     
        {cartitem.length > 0 ? (
          cartitem
          .map((item, index) => (
            <div className="cart" key={index}>

            <div className='name-img'>
              <img src={item.img} alt={item.title} />
             
                <div className='pname'>{item.title}</div>
                </div>
               
                <div className="quantity-controls">
                  <button onClick={() => handleDecrease(item.id)}>-</button>
                  <span>{item.quantity}</span>
                  <button onClick={() => handleIncrease(item.id)}>+</button>
              </div>

              <p>Price:  ₹{loading ? 0:item.quantity*item.price}</p>
<div className='close' onClick={() =>handleDelete(item.id)}><IoMdClose /></div>
            </div>
          ))
        ) : (
          <h3>Cart Is Empty</h3>
        )}

        <div className='total-p'>Total Price:₹{loading ? 0 :TotalValue}</div>
      {cartitem.length > 0 ? <button onClick={handleorder}>Checkout <FaLongArrowAltRight/></button>:null}
      </div>
    )}
  </div>
  
  );
};

export default Cartadd;
