import React from "react";
import "./hpage.css";
import { Link } from "react-router-dom";
import Prod1 from "../../assets/Homeimg/Homesec4-img1.jpg"
import Prod2 from "../../assets/Homeimg/Homesec4-img2.jpg"

const Homesec4 = () => {
  return (
    <div className="Homesec4A">
      <div className="Homesec4main">
        <div className="Homesec4items1">
          <img
            src={Prod1}
            alt=""
            className="Homesec4imgA"
          />
        </div>
        <div className="Homesec4items2">
          <h6 className="Hsec4h6a">-WELCOME-</h6>
          <h3 className="Hsec4h3b">Dining At Smile Cafe</h3>
          <p className="Hsec4pa">
            Welcome to Cafe Point, where every cup tells a story and every sip
            is a moment of pure bliss. Join us in our cozy haven, nestled in the
            heart of the city, as we invite you on a delightful journey of
            flavors, aromas, and warm conversations.For a truly memorable dining
            experience, cuisine and atmosphere are paired as thoughtfully as
            Food and Desserts.
          </p>
          <button className="Hsec4btn1">
            <Link to="/booktable">Book Table</Link>
          </button>
        </div>
        <div className="Homesec4items1A">
          <img
            src={Prod2}
            alt=""
            className="Homesec4imgA"
          />
        </div>
      </div>
    </div>
  );
};

export default Homesec4;
