import React from "react";
import "./Asec2.css";
import { useNavigate } from "react-router-dom";
import Prod from "../../assets/Aboutimg/single-food-5.jpg"

const Asec2 = () => {
  const Navigate =useNavigate()
  return (
    <div className="Asec2A1">
      <div className="Asec2A2">
        <div className="Asec2A3">
          <img
            className="Asec2img1"
            src={Prod}
            alt=""
          />
        </div>
        <div className="Asec2A4">
          <h3 className="Asec2h3">WELCOME TO SMILE CAFE</h3>
          <p className="Asec2p1">
            Welcome to Cafe Point, where every cup tells a story and every sip
            is a moment of pure bliss. Cafe Point is the perfect destination to
            savor life's simple pleasures. Step inside, take a seat, and let our
            dedicated team of baristas serve you an unforgettable experience
            that will keep you coming back for more. Welcome home to Cafe Point,
            where coffee meets moments of pure joy.
          </p>
          <button className="Asec2btn1" onClick={() => Navigate('/about')}>
           READ MORE
          </button>
        </div>
      </div>
    </div>
  );
};

export default Asec2;
