import React from "react";
import "./Service.css";
import Prod1 from "../../assets/Serviceimg/S2-logo-1a.png"
import Prod2 from "../../assets/Serviceimg/S2-logo-1b.png"
import Prod3 from "../../assets/Serviceimg/S2-logo-1c.png"
import Prod4 from "../../assets/Serviceimg/S2-logo-1d.png"
import Prod5 from "../../assets/Serviceimg/S2-logo-1e.png"
import Prod6 from "../../assets/Serviceimg/S2-logo-1f.png"
import Prod7 from "../../assets/Serviceimg/S2-logo-1g.png"
import Prod8 from "../../assets/Serviceimg/S2-logo-1h.png"

const Servicesec2 = () => {
  return (
    <div className="Servicesec2a">
      <div className="servicesec2A1">
        <div className="servicesec2item">
          <img src={Prod1} alt="" className="servicesec2-icons" />
          <h1 className="servicesec2h1a">ITALIAN SPECIALTY</h1>
          <p className="servicesec2pa1">
          Variety of toppings, vegetables, artisanal Cheeses.
          </p>
        </div>

        <div className="servicesec2item">
        <img src={Prod2} alt="" className="servicesec2-icons" />
        <h1 className="servicesec2h1a">JUICY BURGERS</h1>
          <p className="servicesec2pa1">
          Seasoned to perfection, ensuring a tender and flavorful bite.
          </p>
        </div>

        <div className="servicesec2item">
        <img src={Prod3} alt="" className="servicesec2-icons" />
        <h1 className="servicesec2h1a">EXQUISITE PASTA</h1>
          <p className="servicesec2pa1">
          Comforting and hearty shapes like penne, rigatoni, fusilli.
          </p>
        </div>

        <div className="servicesec2item">
        <img src={Prod4} alt="" className="servicesec2-icons" />
        <h1 className="servicesec2h1a">TASTY DESERTS</h1>
          <p className="servicesec2pa1">
          Each bite is a symphony of flavors and the smoothness.
          </p>
        </div>
        <div className="servicesec2item">
        <img src={Prod5} alt="" className="servicesec2-icons" />
        <h1 className="servicesec2h1a">DELICIOUS JUICE</h1>
          <p className="servicesec2pa1">
          Natural sweetness and refreshing taste of fresh fruits.
          </p>
        </div>

        <div className="servicesec2item">
        <img src={Prod6} alt="" className="servicesec2-icons" />
        <h1 className="servicesec2h1a">JUICY SHAKE</h1>
          <p className="servicesec2pa1">
          Blend the fruits with creamy ice cream or yogurt.
          </p>
        </div>

        <div className="servicesec2item">
        <img src={Prod7} alt="" className="servicesec2-icons" />
        <h1 className="servicesec2h1a">GREAT CHEFS</h1>
          <p className="servicesec2pa1">
          Expertise in various cuisines,cooking techniques,creativity, innovation.
          </p>
        </div>

        <div className="servicesec2item">
        <img src={Prod8} alt="" className="servicesec2-icons" />
        <h1 className="servicesec2h1a">FAST DELIVERY</h1>
          <p className="servicesec2pa1">
            Provide a seamless and efficient delivery experience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Servicesec2;
