import React from 'react'
import './Contact.css';
import ContactForm from './ContactForm';
import Cdetails from './Cdetails';

const Contact = () => {
  return (
  <div>

    <div className='contactimg1' >
      <p className='pCI1'>CONTACT US</p>
      <p className='pCI2'>"WE'RE EASY TO GET IN TOUCH WITH YOU"</p>
    </div>

    <Cdetails/>
    <ContactForm/>
  </div>

  )
}

export default Contact