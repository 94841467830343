import React, { useContext } from 'react'
import "./Loader.css"
import MyContext from '../Context/MyContext'

const Loader = () => {
    const {loading} = useContext(MyContext)
  return (

    <>

    {
        loading &&
    <div class="container-box">
    <div class="box">
        <div class="front"></div>
        <div class="back"></div>
        <div class="left"></div>
        <div class="right"></div>
        <div class="top"></div>
        <div class="bottom"></div>
    </div>
</div>
    }

</>
  )
}

export default Loader