import React, { useContext } from "react";
import "./Register.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";

import TextField from "@mui/material/TextField";
import { AccountCircle, Email, Phone } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import KeyIcon from "@mui/icons-material/Key";
import FormControlLabel from "@mui/material/FormControlLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { AiOutlineArrowLeft } from "react-icons/ai";
import MyContext from "../../Context/MyContext";



const Register = () => {

  const {setSneck,setMessage,setLoading,loading,Navigate} = useContext(MyContext) 

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobileno: "",
      password: "",
      cpassword: "",
      termsAndConditions: false,
    },
    validationSchema:  yup.object({
      name: yup
        .string("Enter your Name")
        .required("Name is required")
        .matches(/^([^0-9]*)$/, "Don't allow Numeric Value"),
    
      email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
    
      mobileno: yup
        .string("Enter your Mobile.No")
        .matches(/^[0-9]{10}$/, "Mobile number is not valid")
        .required("Mobile.No is required"),
    
      password: yup
        .string("Enter your Password")
        .required("Password is required")
        .matches(/^\S+$/, "Password cannot contain whitespace")
        .min(8, "Password must be at least 8 characters")
        .max(12, "Password must be at most 12 characters"),
    
      cpassword: yup
        .string("Enter your Password")
        .required("Re-Enter the Password")
        .oneOf([yup.ref("password"), null], "Password must be same"),
    
      termsAndConditions: yup
        .boolean()
        .oneOf([true], "You must accept the terms and conditions")
        .required("You must accept the terms and conditions"),
    }),
    onSubmit: async(values,{resetForm}) => {
      setLoading(true)
      const response = await fetch('https://emongo.vercel.app/register', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
        'Content-type': 'application/json',
        },
        });

        const data = await response.json()

        if(data.success){
          setMessage(data.message)
          setSneck(true)
          Navigate('/login')
          resetForm()

        }
        else{
          setMessage(data.error)
          setSneck(true)
        }
          setLoading(false)
        
    },
  });

  return (
    <div>
      <div className="register">
        <form className="Rform" onSubmit={formik.handleSubmit}>
     
        {!loading &&    <><i className="arrowback">
          <Link to="/login">
              <AiOutlineArrowLeft />
            </Link>
          </i>
          <h1 className="RF-h1a1">Registration Form</h1>
          </>}
     

          <div className="textR">
            <TextField
              className="textR1"
              label="User-Name"
              placeholder="User-Name"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              // variant="filled"
            />

            <TextField
              className="textR1"
              label="Mobile.No"
              placeholder="Mobile.No"
              id="mobileno"
              name="mobileno"
              value={formik.values.mobileno}
              onChange={formik.handleChange}
              error={formik.touched.mobileno && Boolean(formik.errors.mobileno)}
              helperText={formik.touched.mobileno && formik.errors.mobileno}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
              // variant="outlined"
            />

            <TextField
              className="textR1"
              label="E-mail ID"
              placeholder="Email-ID"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
              // variant="standard"
            />

            <TextField
              className="textR1"
              label="Password"
              placeholder="Password"
              // id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // variant="filled"
            />
            <TextField
              className="textR1"
              label="Confirm-Password"
              placeholder="Re-Enater Password"
              type="password"
              id="cpassword"
              name="cpassword"
              value={formik.values.cpassword}
              onChange={formik.handleChange}
              error={
                formik.touched.cpassword && Boolean(formik.errors.cpassword)
              }
              helperText={formik.touched.cpassword && formik.errors.cpassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            
            />
            <FormControlLabel
            control={<Checkbox
                name="termsAndConditions"
                id="termsAndConditions"
                checked={formik.values.termsAndConditions}
                onChange={formik.handleChange}
                color="primary"
            />}
            label="Terms & Conditions"
        />
        {formik.touched.termsAndConditions && formik.errors.termsAndConditions ? (
            <div>{formik.errors.termsAndConditions}</div>
        ) : null}
          </div>

          <div className="Rbtn1">
            <button className="Rbtn2"   disabled={loading?true:false}>
            {loading ? 'Submiting...':'SUBMIT'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
