import React, { useEffect, useContext } from 'react';
import './Booking.css';
import MyContext from '../../Context/MyContext';

const Booking = () => {
  const Token = sessionStorage.getItem('token');
  const { bookings, setDataload,setBookings,dataload,Navigate,setMessage,setSneck } = useContext(MyContext);
 

  useEffect(() => {

    if(!Token){
      setMessage("please login to view cart")
     setSneck(true)
     setTimeout(() => {
      Navigate('/login')
    }, 3000);
    }
  
    const fetchBookings = async () => {
      try {
        const response = await fetch('https://emongo.vercel.app/book-info', {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch bookings');
        }
        const data = await response.json();
        setBookings(data.bookInfo);
        setDataload(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Error fetching bookings:', error);
        setDataload(false);
      }
    };
    fetchBookings();
  }, [Token, setBookings,setDataload,Navigate,setMessage,setSneck]);

  return (
    <div className="booking-container">
      <h2>Booking Details</h2>
      {dataload ? (
        <p>Loading...</p>
      ) : (
        <div className="grid-container">
         {bookings.length > 0 ? (
            bookings
              .sort((a, b) => b._id.localeCompare(a._id))
              .map((booking, index) => (
                <div className="card" key={index}>
                  <li><b>Name:</b>{booking.name}</li>
                  <li><b>Email:</b>{booking.email}</li>
                  <li><b>Mobile:</b>{booking.mobile}</li>
                  <li><b>Seat:</b>{booking.numofpass}</li>
                  <li><b>Date:</b>{new Date(booking.date).toLocaleDateString('en-GB')}</li>
                  <li><b>Time:</b>{booking.time}</li>
                </div>
              ))
          ) : (
            <p>Booking is empty</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Booking;
