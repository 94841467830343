import React, { useContext, useState } from "react";
import "./Mynavbar.css";

import { FaUserCircle } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { BsCart4 } from "react-icons/bs";
import { Link, useLocation} from "react-router-dom";
import DrawerReact from "./DrawerReact";
import MyContext from "../../Context/MyContext";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { AiFillCodeSandboxCircle } from "react-icons/ai";
import { IoIosLogOut } from "react-icons/io";
import { TbBrandBooking, TbTruckDelivery } from "react-icons/tb";

const Mynavbar = () => {
const{Navigate,handleLogout,menu,setMenu,cartitem,handlecartopen,name,handlebookopen} = useContext(MyContext)
const Token = sessionStorage.getItem('token');
  const [isOpen, setIsOpen] = useState(false);
  const toggelDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const location = useLocation()
  const paths = ['/checkout', '/payment'];
  if (paths.includes(location.pathname)){
    return <div className="navbar">
    <div className="logo" onClick={() => Navigate('/')}>
      <h5 > SMILE CAFE POINT</h5>
    </div>

  </div>
  }

  return (
    <>
      <div className="navbar">
        <div className="logo" onClick={() => Navigate('/')}>
          <h5> SMILE CAFE POINT</h5>
        </div>

        <div className="list">
          <li className="l">
            <Link to="/">Home </Link>
          </li>
          <li className="l">
            <Link to="/menu">Menu</Link>
          </li>
          <li className="l">
            <Link to="/service">Services</Link>
          </li>
          <li className="l">
            <Link to="/about">About</Link>
          </li>
          <li className="l">
            <Link to="/shop">Shop</Link>
          </li>

          <li className="l">
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/booktable">
              <div className="bookbtn">Book Table</div>
            </Link>
          </li>
        </div>

        <div class="icon">
       
           
          <div class="i1"  onClick={handlecartopen}>
          <span><BsCart4 color="white"/></span>
          
          <p>{cartitem?.length || 0}</p>

        </div>
           
         

          
          <i className="i2">
           
             
             
            {Token ? <span><IoIosArrowDropdownCircle color="white" style={{cursor:'pointer'}} onClick={() =>setMenu(!menu)}/> {name.length>7?'':name}</span>:
              <Link to="/login">
              <FaUserCircle />
              </Link>
            }
         

           
      
          </i>
          <i className="i3" onClick={toggelDrawer}>
            <HiMenu />
          </i>
        </div>
      </div>
      <div className="drawer">
        <DrawerReact open={isOpen} onclose={toggelDrawer} />
      </div>

   {  menu && <div class="search-list">
<li>welcome! {name}</li>
      <li onClick={() =>handlebookopen('booking')}>Booking Details <TbBrandBooking size={20}/></li>
      <li onClick={() =>handlebookopen('shipping-info')}>Shipping Details <TbTruckDelivery size={20}/></li>
      <li onClick={() =>handlebookopen('order-details')}>Order-Details Details <AiFillCodeSandboxCircle size={20}/></li>
      <li onClick={handleLogout}>Logout  <IoIosLogOut size={20}/></li>
     
     </div>}
    </>
  );
};

export default Mynavbar;
