import React, { useEffect, useState } from 'react'
import MyContext from './MyContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const MyContextProvider = ({children}) => {


const [sneck,setSneck] = useState(false)

const [loading,setLoading] = useState(false)

useEffect(() => {
    let timeout;
    if (sneck) {
        timeout = setTimeout(() => {
            setSneck(false);
        }, 3000);
    }
    return () => clearTimeout(timeout);
}, [sneck, setSneck]);

const[message,setMessage] = useState()

const [token, setToken] = useState('');

useEffect(() => {
  const storedToken = sessionStorage.getItem('token');
  if (storedToken) {
    setToken(storedToken);
  }
}, []);

const Navigate = useNavigate()

const handleLogin = (newtoken,newname) =>{
    setToken(newtoken);
    setSneck(true);
    setName(newname);
    sessionStorage.setItem('name', newname);
  sessionStorage.setItem('token', newtoken);
    Navigate('/');
}

const handleLogout = () => {
    const confirmed = window.confirm('Are you sure you want to log out?');
    if (confirmed) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('cartItems');
      sessionStorage.removeItem('cartorder');
      sessionStorage.removeItem('name');
      window.location.reload()
      setToken('');
      setName('')
      setBookings([])
      setCartitem([])
      orders([])
      Navigate('/');
      setMenu(false)
      setShippingInfo({})
    }
  };


  const [menu,setMenu] = useState(false)

  const [bookings, setBookings] = useState([]);

  const[dataload,setDataload] = useState(true)

  const [data,setData] = useState([])

  useEffect(()=>{
    axios.get('https://emongo.vercel.app/api')
    .then((res)=> setData(res.data.data),
    setDataload(false)
   
      
              
    )
    },[])

    const[productid,setProductid] = useState('')


    const handleCart =async(id) =>{

      
     
     const Token = sessionStorage.getItem('token')

  
        if (!Token) {
          setMessage('please login first to add products')
          setSneck(true)
          setTimeout(() => {
            Navigate('/login')
          }, 3000);
          return;
        }
      

         setLoading(true)
         setProductid(id)
          const response = await fetch('https://emongo.vercel.app/add-to-cart', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Token}`
            },
            body: JSON.stringify({id:id})
          });
      
          const data = await response.json()
      
          if (data.success) {
            setMessage(data.message)
            setSneck(true)
            setCartitem(data.cartinfo)
          } else {
            setMessage(data.error)
            setSneck(true)
          }
          setLoading(false)
        
   
    }

    const [cartitem, setCartitem] = useState(() => {
      const storedCartItems = sessionStorage.getItem('cartItems');
      return storedCartItems ? JSON.parse(storedCartItems) : [];
    });
    
    useEffect(() => {
      sessionStorage.setItem('cartItems', JSON.stringify(cartitem));
    }, [cartitem]);


 





   

   
    const isProductInCart = (id) => {
      if (cartitem) {
          return cartitem.find(item => item.id === id);
      }
      return false;
  };

  const handlecartopen = () =>{
if(token){
  Navigate('/cart')
}
else{
  setMessage("please login to view cart")
  setSneck(true)
  setTimeout(() => {
   Navigate('/login')
 }, 3000);
}
  }

  const [name,setName] = useState('')

  useEffect(() => {
    const storedname = sessionStorage.getItem('name');
    if (storedname) {
      setName(storedname);
    }
  }, []);


  
const handleDelete = async (id) => {
  setLoading(true)
  try {
    const Token = sessionStorage.getItem('token');
  

    const response = await fetch('https://emongo.vercel.app/remove-from-cart', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Token}`
      },
      body: JSON.stringify({ id:id })
    });

    const data = await response.json();
    if(data.success){

     setMessage(data.message)
     setSneck(true)
     setCartitem(data.cartinfo)
     window.location.reload()
      
     
    }
    else{
      
     setMessage(data.error)
     setSneck(true)
    }
    setLoading(false)
  } catch (error) {
    console.error('Error removing from cart:', error);

  }
};



const handleIncrease = async (id) => {
 
  try {
    setLoading(true)
    const Token = sessionStorage.getItem('token');
   

      const response = await fetch('https://emongo.vercel.app/increase-quantity', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Token}`
        },
        body: JSON.stringify({ id:id})
      });
    

    

    const data = await response.json();
    if(data.success){
      setCartitem(data.cartinfo)
      window.location.reload()

    
  } else {
    setMessage(data.error)
    setSneck(true)
 
  }
  setLoading(false)
  } catch (error) {
    console.error('Error removing from cart:', error);
    setLoading(false)
  }
};

const handleDecrease = async (id) => {
 
  try {
    setLoading(true)
    const Token = sessionStorage.getItem('token');
   

      const response = await fetch('https://emongo.vercel.app/decrease-quantity', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Token}`
        },
        body: JSON.stringify({ id:id})
      });
    

    

    const data = await response.json();
    if(data.success){
      setCartitem(data.cartinfo)
      window.location.reload()

    
  } else {
    setMessage(data.error)
    setSneck(true)
 
  }
  setLoading(false)
  } catch (error) {
    console.error('Error removing from cart:', error);
    setLoading(false)
  }
};



  const TotalValue = cartitem?.reduce((acc, item) => acc + (item.price * item.quantity), 0);



const Token = sessionStorage.getItem('token')

const [shippinginfo,setShippingInfo] = useState({})

useEffect(() => {
 

  const fetchBookings = async () => {
    try {
      const response = await fetch('https://emongo.vercel.app/shipping-info', {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch bookings');
      }
      const data = await response.json();
     
      setShippingInfo(data.data)
      setDataload(false);
  
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setDataload(false);
   
    }
  };
  fetchBookings();
}, [Token,Navigate]);

const [openship,setOpenship] = useState(false)

const handlebookopen = (path) =>{

window.open(`/${path}`,'_blank')
setMenu(false)

}



const handleconfirm = async () => {
  const Token = sessionStorage.getItem('token');

  try {
    setLoading(true);
    const response = await fetch('https://emongo.vercel.app/order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Token}`
      },
      body: JSON.stringify({
        items: cartitem.map(item => ({ id: item.id, quantity: item.quantity }))
      })
    });

    const data = await response.json();

    if (data.success) {
      setMessage(data.message);
      setCartitem([])
      sessionStorage.removeItem('cartItems')
      setSneck(true);
      setTimeout(() => {
        window.location.href = '/confirm'
      }, 500);
    } else {
      setMessage(data.error);
      setSneck(true);
    }
  } catch (error) {
    setMessage('An error occurred, please try again');
    setSneck(true);
    console.error('Error during booking:', error);
  } finally {
    setLoading(false);
  }
};







const handleorder = async () => {
Navigate('/checkout')



};


const[orders,setOrders]= useState([])

const [forgot,setForgot] = useState(false)
return (
<MyContext.Provider value={{forgot,setForgot,orders,setOrders,handleorder,handleconfirm,handlebookopen,openship,setOpenship,shippinginfo,setShippingInfo,TotalValue,handleDecrease,handleIncrease,name,handleDelete,setName,handlecartopen,isProductInCart,cartitem,setCartitem,productid,handleCart,setDataload,dataload,data,setData,bookings,setBookings,menu,setMenu,handleLogin,handleLogout,loading,setLoading,sneck,setSneck,message,setMessage,token,setToken,Navigate}} >
{children}
</MyContext.Provider>
)
}

export default MyContextProvider