import {Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';

import Mynavbar from './Navbar_items/Navigation/Mynavbar';
import Home from "./React_Routing/Home/Home";
import About from "./React_Routing/About/About";
import Menu from "./React_Routing/Menu/Menu";
import Service from "./React_Routing/Service/Service";
import Contact from "./React_Routing/Contact/Contact";
import Login from './Navbar_items/Login/Login';
import Register from './Navbar_items/Login/Register';
import Footer from './React_Routing/FooterSection/Footer';
import Booking from './React_Routing/BookTable/Booking';
import { Cartitems } from './React_Routing/Shoping/Cartitems';
import Cartadd from './React_Routing/Shoping/Cartadd';
import ScrollToTop from "react-scroll-to-top";
import MyContextProvider from './Context/MyContextProvider';
import Alert from './Common/Alert/Alert';
import Loader from './Loader/Loader';
import GoToTop from './Common/GoToTop';
import NoPage from './NoPage/NoPage';
import BookingDetails from './Common/Booking/Booking'
import Checkout from './Checkout/Checkout';
import Payment from './Payment/Payment';
import Confirm from './Confirmation/Confirm';
import Order from './OrderDetails/Order';



function App() {
  const Token = sessionStorage.getItem('token');
 
 

  return (
    <div className="App">
    
    <Router>
    <ScrollToTop smooth style={{backgroundColor:'#C19977'}} color='white'/>
    <MyContextProvider>
    <Alert/>
    <Loader/>
    <GoToTop/>
      <Mynavbar />
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="*" element={<NoPage/>}/>
        <Route path="/booking" element={<BookingDetails/>}/>
        <Route path="/payment" element={<Payment/>}/>
        <Route path="/confirm" element={<Confirm/>}/>
        <Route path="/order-details" element={<Order/>}/>
        
       
          <Route path="/checkout" element={<Checkout/>}/>
        
       
       
        

        
          <Route path="/shipping-info" element={<Checkout/>}/>
        
       
       
     
        <Route path="/menu" element={<Menu/>}/>
        <Route path="/service" element={<Service/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
        
        <Route path="/booktable" element={<Booking/>}/>
       <Route path="/shop" element={<Cartitems />}/>
       <Route path="/cart" element={<Cartadd />}/>

      

        {!Token ?  <Route path="/login" element={<Login/>}/>:
        <Route path="/login" element={<Home/>}/>
      }
       

        {!Token ?  <Route path="/register" element={<Register/>}/>:
        <Route path="/register" element={<Home/>}/>
      }

      </Routes>

      <Footer/>
      </MyContextProvider>
      
    </Router>
      
    </div>
  );
}

export default App;


// border-color: #ed6c02
// font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
