import React, { useContext } from "react";
import "./Contact_form.css";
import { useFormik } from "formik";
import * as yup from "yup";
import Prod from "../../assets/Contactimg/c-img4.jpg"
import MyContext from "../../Context/MyContext";


const ContactForm = () => {

  const {setSneck,setMessage,setLoading,loading} = useContext(MyContext)

const validationSchema = yup.object({
  name: yup
    .string("Enter your Name")
    .required("Name is required")
    .matches(/^([^0-9]*)$/, "Don't allow Numeric Value")
    .min(3, '* Name should atleast 4 charcter'),

  email: yup
    .string("Enter your email")
    .matches(/^\S*$/, '* email cannot contain whitespace')
    .email("Enter a valid email")
    .required("Email is required"),

  mobileno: yup
    .string("Enter your Mobile.No")
    // .matches(/^[0-9]{10}$/, "Mobile number is not valid")
    .matches(/^\S*$/, '* Mobile no cannot contain whitespace')
    .min(10, '* Mobile no should be 10 Number')
    .max(12, '* Mobile no should be 10 Number')
    .required("Mobile.No is required")
    .matches(/^(\+?\d{1,3}[- ]?)?\d{10}$/, '* Mobile number is not valid'),

    message: yup
    .string("Enter your message")

    .required("Mobile.No is required")

});

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobileno: "",
      message:""

    },
    validationSchema: validationSchema,
    onSubmit: async(values,{resetForm}) => {
      setLoading(true)
      const response = await fetch('https://emongo.vercel.app/contact', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
        'Content-type': 'application/json',
        },
        });

        const data = await response.json()

        if(data.success){
          setMessage(data.message)
          setSneck(true)
          resetForm()

        }
        else{
          setMessage(data.error)
          setSneck(true)
        }
          setLoading(false)
        
    },
  });

  return (
    <div className="contactform">
      <div className="Form-img">
      <img className="Cimg-1" src={Prod} alt="" />
      </div>
      <form className="glass" onSubmit={formik.handleSubmit}>
        <div>
          <h2 className="Contacth21a">Contact Us</h2>
        </div>

        <div className="text">
          <div>
          <input
            type="text"
            className="text111"
            placeholder="Enter Your Name"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          {formik.errors.name && formik.touched.name ?
            <p className='form-errora1'>{formik.errors.name}</p> : null}
          </div>

          <div>
          <input
            type="text"
            className="text111"
            placeholder="E-mail Id"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          {formik.errors.email && formik.touched.email ?
            <p className='form-errora1'>{formik.errors.email}</p> : null}
          </div> 

          <div>
          <input
            type="text"
            className="text111"
            placeholder="Mobile.No."
            id="mobileno"
            name="mobileno"
            value={formik.values.mobileno}
            onChange={formik.handleChange}
            error={formik.touched.mobileno && Boolean(formik.errors.mobileno)}
            helperText={formik.touched.mobileno && formik.errors.mobileno}
          />
          {formik.errors.mobileno && formik.touched.mobileno ?
            <p className='form-errora1'>{formik.errors.mobileno}</p> : null}
            </div>

          <div>
          <input
            type="text"
            className="text111"
            placeholder="Message..."
            id="message"
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
          {formik.errors.message && formik.touched.message ?
            <p className='form-errora1'>{formik.errors.message}</p> : null}
            </div>

          <button className="Contactbtn1A" type="submit" 
          disabled={loading?true:false}>
          {loading ? 'Submiting...':'SUBMIT'}
          </button>

        </div>
      </form>
    </div>
  );
};

export default ContactForm;
