import React from 'react'
import "./NoPage.css"

const NoPage = () => {
  return (
    <div class="nopage">
    <img src="https://i.ibb.co/d6SMLfS/no-page.png" alt=""/>
</div>
  )
}

export default NoPage