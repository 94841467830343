import React, { useContext } from "react";
import "./login.css";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Email } from "@mui/icons-material";
import KeyIcon from '@mui/icons-material/Key';

import InputAdornment from "@mui/material/InputAdornment";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from 'formik';
import * as yup from 'yup';
import MyContext from "../../Context/MyContext";
import Forgot from "./ForgotPassword/Forgot";




const Login = () => {

  const {setSneck,setMessage,setLoading,loading,handleLogin,setCartitem,setForgot} = useContext(MyContext) 

/*.................... for showPassword............................*/

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

/*.................... for validation.................................*/



  const formik = useFormik({
    initialValues: {
      email:'',
      password:'',

    },
     
    validationSchema:  yup.object({

      email: yup
          .string('Enter your email')
          .matches(/^\S*$/, 'email cannot contain whitespace')
          .email('Enter a valid email')
          .required('Email is required'),
       
      password: yup
          .string('Enter the password')
          .min(8,'password atleast 8 charcter')
          .required('password is required'),

  }),
  onSubmit: async (values, { resetForm }) => {
    setLoading(true);

  
    const response = await fetch('https://emongo.vercel.app/login', {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-type': 'application/json',
      },
    });
  
    const data = await response.json();
  
    if (data.success) {
     
      resetForm();
      setCartitem(data.cartinfo)
     handleLogin(data.data,data.name)
     setMessage(data.message)
   
  
      // Navigate to the stored path
    
    } else {
      setMessage(data.error);
      setSneck(true);
    }
    
    setLoading(false);
  },
  
  
  })

  return (
    <>
    <div className="l1">
      <form className="loginf" onSubmit={formik.handleSubmit}>

        <div className="tfield">

          <h1 className="my">Login</h1>
       <div className="emailpass">
              <TextField
              color="warning" focused 
              className="t1"
              name="email"
              label="Email"
              placeholder="Email-Id"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
              variant="outlined"/>
              
              <TextField className="t1" label="Password" placeholder="Password"
              color="warning" focused
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password} 
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                    <KeyIcon/>
                    </InputAdornment>
                    ),
                endAdornment:(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }} />

       </div>
         
        <button type="submit" className="loginbtn "   disabled={loading?true:false}>
          {loading ?'wait...':'Login'}
        </button>

         
  
         { !loading && <p className="Lp1">New User ? &nbsp;
            <Link to="/register">Register here</Link>
          </p>}

          { !loading && <p className="forgot" onClick={() => setForgot(true)}> 
          Forgot Password
        </p>}
          <br/><br/>
        </div>
      </form>
    </div>

    <Forgot/>

    </>
  );
};

export default Login;
