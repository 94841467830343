import React from "react";
import "./Asec2.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Aboutsec3 = () => {
  const Responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="Aboutsec3a">
      <div className="feedbacksec1">
        <h6 className="FBtitleh6">-TESTIMONIALS-</h6>
        <h3 className="FBtitleh3">What Clients Say</h3>
        <Carousel
          className="Fbslide"
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
          showDots={true}
          responsive={Responsive}
        >
          <div className="Fb1">
                  <h5 className="fbtexth5">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  nostrud exercitation ullamco laboris.”</h5>
                  <p className="Fbline1">____________</p>
                  <p className="Fbname1">Dhruv Patel</p>
          </div>
          <div className="Fb1">
                  <h5 className="fbtexth5">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  nostrud exercitation ullamco laboris.”</h5>
                  <p className="Fbline1">____________</p>
                  <p className="Fbname1">Chintan Patel</p>
          </div>
          <div className="Fb1">
                  <h5 className="fbtexth5">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  nostrud exercitation ullamco laboris.”</h5>
                  <p className="Fbline1">____________</p>
                  <p className="Fbname1">Meet Bhatiya</p>
          </div>
          <div className="Fb1">
                  <h5 className="fbtexth5">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  nostrud exercitation ullamco laboris.”</h5>
                  <p className="Fbline1">____________</p>
                  <p className="Fbname1">Rajkumar Rao</p>
          </div>

        </Carousel>
      </div>
    </div>
  );
};

export default Aboutsec3;
